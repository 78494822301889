import React, { FunctionComponent, useEffect, useState } from "react"
import Style from "./instafeed.style"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import {
  getInstagramImagesByHashtag,
  InstagramPost,
} from "../../services/eventsService"


const Hashtag = ({
  hashtagTitle,
  images,
  color,
}: any) => {
  const settings = {
    centerMode: true,
    infinite: true,
    slidesToShow: 1,
    speed: 500,
    swipeToSlide: true,
    variableWidth: true,
    dots: true,
    arrows: true,
    autoplay: true,
    pauseOnHover: true,
    nextArrow: (
      <Style.CarouselNextArrowWrapper>
        <Style.CarouselNextArrow />
      </Style.CarouselNextArrowWrapper>
    ),
    prevArrow: (
      <Style.CarouselPrevArrowWrapper>
        <Style.CarouselPrevArrow />
      </Style.CarouselPrevArrowWrapper>
    ),
    adaptiveHeight: true,
  }

  if (images.length === 0) return null

  return (
    <Style.Event.Container style={{ backgroundColor: `${color}` }}>
      <Style.Event.Header>
        {"Share the experience"}
      </Style.Event.Header>
      <Style.Event.Hashtag style={{fontSize:'38px'}}>#{hashtagTitle}</Style.Event.Hashtag>
      <Style.PostsContainer>
        <Slider {...settings}>
          {images.map((image:any) => (
            <Style.Event.Post key={image.title}>
              <Style.Event.InstaImage
                src={image.fluid.src}
                alt={image.title}
              />
            </Style.Event.Post>
          ))}
        </Slider>
      </Style.PostsContainer>
    </Style.Event.Container>
  )
}

export default Hashtag
